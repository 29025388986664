import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CHeroImg,
  CustomLink,
  CSectTitle,
  CDefinition,
  LPlan,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'couture creo',
            ja: 'クチュール クレオ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/facilities/couturecreo/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/facilities/couturecreo/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宿泊',
                path: '/stay/',
              },
              {
                label: '施設',
                path: '/stay/facilities/',
              },
            ],
            current: {
              label: 'COUTURE CREO（クチュール クレオ）',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 u_pb120_sp u_pb160_pc">
        <LWrap>
          <h2 className="c_headingLv2">特別な日をもっと特別に</h2>
          <p className="c_sectLead u_mb30">
            クチュールクレオ美容室は、衣裳の知識も持ったスタイリストが在籍しています。{' '}
            <br />
            ヘアメイクだけではなく、トータルコーディネイトができる専門スタッフだからこそ出来る、
            <br className="u_pc" />
            お一人おひとりに合ったヘアメイクのご提案を。すべての女性を素敵な花嫁様へと導きます。
          </p>
          <p className="c_sectLead u_mb55">
            ご結婚式での新郎新婦様はもちろん、ご列席の方や、七五三・成人式のヘアメイクも承っております。
          </p>
          <CHeroImg
            exClass="u_mbExLarge"
            img={{
              src: '/assets/images/stay/facilities/couturecreo/img_hero.png',
              alt: '',
            }}
          />
          <CSectTitle
            title={{ ja: <>営業時間 / ご利用案内</>, en: <>GUIDE</> }}
          />
          <CDefinition
            exClass="u_mb30"
            data={[
              {
                title: <>営業時間</>,
                text: <>12：00～18：00</>,
              },
              {
                title: <>定休日</>,
                text: <>火曜日（祝日を除く）</>,
              },
              {
                title: <>お問合せ</>,
                text: (
                  <>
                    022-377-4435 <br />
                    <CustomLink
                      to="https://www.couturecreo.com"
                      rel="noopener noreferrer"
                      target="blank"
                    >
                      <i className="icon-simpleArrow"></i>クチュールクレオ HP
                    </CustomLink>
                    <br />
                    <CustomLink
                      to="https://instagram.com/creosendai"
                      rel="noopener noreferrer"
                      target="blank"
                    >
                      <i className="icon-simpleArrow"></i>美容室 Instagram
                    </CustomLink>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
